/* HeroBanner.css */
.hero-banner {
  color: white; /* Adjust color as needed */
  padding: 100px 0; /* Default padding */
  position: relative; /* For positioning the arrow */
  text-align: center;
}

/* Styles for large hero banner */
.hero-banner.large {
  padding: 250px 0; /* Larger padding for a larger banner */
}

/* Styles for small hero banner */
.hero-banner.small {
  padding: 50px 0; /* Smaller padding for a smaller banner */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-banner {
    padding: 50px 0;
  }
  .hero-banner.large {
    padding: 100px 0; /* Adjust for smaller screens */
  }
  .hero-banner.small {
    padding: 25px 0; /* Adjust for smaller screens */
  }
}

.breadcrumb {
  background: transparent; /* Makes the breadcrumb background transparent */
  justify-content: center; /* Center the breadcrumbs */
}

.breadcrumb-item a {
  color: white; /* Adjust link color as needed */
}

.breadcrumb-item + .breadcrumb-item::before {
  color: white; /* Adjust the separator color as needed */
}

.hero-banner-button {
  background-color: #007bff; /* Example color, adjust as needed */
  color: white;
  padding: 1rem 2rem; /* Adjust padding to match design */
  font-size: 1rem; /* Adjust font size as needed */
  text-transform: none; /* If you don't want uppercase text */
  border-radius: 2rem; /* If you want rounded corners */
  box-shadow: none; /* If you don't want any shadow */
  margin-bottom: 50px; /* Adjust this value to the desired space */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
